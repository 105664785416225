import $ from "jquery";
globalThis.jQuery = $;

import { library, dom  } from '@fortawesome/fontawesome-svg-core'

import { faCommentDots, faStarHalfStroke, faBars} from '@fortawesome/pro-regular-svg-icons'
import { faHeart} from '@fortawesome/sharp-regular-svg-icons'
import { faStar as fasStar} from '@fortawesome/pro-solid-svg-icons'
import { faCheck as fassCheck, faHeart as fassHeart} from '@fortawesome/sharp-solid-svg-icons'

library.add( faCommentDots, faHeart, fassCheck, fasStar, faStarHalfStroke, fassHeart, faBars);

dom.watch()

// SLICK SLIDER
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';

// GENERAL STYLING
import './styles.scss';

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

(function ($) {

  $(function () {

    'use strict';

    $(document).ready(function(){
      $('#Kleur').next('option').text('Kleur').val(''); // Verander de tekst naar "Kleur" en verwijder de waarde
    });

    // Mega menu class tovoegen
    $('header .menu-item-has-children').each(function() {
      if ($(this).find('.sub-menu > li').length > 6) {
          $(this).addClass('mega-menu');
      }
    });

    // Sidebar filter
    $(".sidebar h4").click(function(){
          var li = $(this).parent();
          if (li.hasClass("toggled")) {
              li.removeClass("toggled");
          } else {
              li.addClass("toggled");
          }
      });

      // Sidebar meer knop
      $(document).ready(function(){
        $(".sf-field-taxonomy-product_cat").each(function() {
            var ul = $(this).find("ul:first");
            if (ul.height() > 170) {
                ul.after('<span class="show-more">Meer</span>');
                ul.addClass("toggled");
            }
        });

        $(".toggle-sidebar").click(function(){
          var form = $(this).nextAll("form").first();
          form.toggleClass("toggled");
          if (form.hasClass("toggled")) {
              $(this).text("Filter sluiten");
          } else {
              $(this).text("Filter openen");
          }
      });
        
        $(".show-more").click(function(){
            var ul = $(this).prev("ul");
            if (ul.hasClass("toggled")) {
                ul.removeClass("toggled");
                $(this).text("Minder");
            } else {
                ul.addClass("toggled");
                $(this).text("Meer");
            }
        });
    });

    // Hover active class toevoegen
    $('header .menu-wrap > li').hover(function() {
      $(this).addClass('active');
      if ($(this).hasClass('mega-menu')) {
          $('.page-overlay').addClass('active');
      }
    }, function() {
        $(this).removeClass('active');
        if (!$('header .menu-wrap > li.mega-menu.active').length) {
            $('.page-overlay').removeClass('active');
        }
    });

    
    // MOBILE MENU submenu
    $('<div class="subarrow"></div>').insertAfter('.menu-wrap li.menu-item-has-children:not(.nav-btn) > a');
    
    $(document).ready(function() {
      $('.mobile-menu .subarrow').each(function() {
        var parentLi = $(this).closest('li');
        var parentText = parentLi.children('a').text(); // Haal de tekst op van de parent <li>
        parentLi.find('ul').prepend('<a class="submenu-back">' + parentText + '</a>'); // Voeg de tekst toe aan .submenu-back
      });
    });
    

    
      $('.mobile-menu').on('click', '.subarrow', function () {
        var parentLi = $(this).closest('li');
        if (parentLi.hasClass('active')) {
          parentLi.removeClass('active');
        } else {
          parentLi.addClass('active');
        }
      });
    
      $('.mobile-menu').on('click', '.submenu-back', function() {
        var parentLi = $(this).closest('li');
        parentLi.removeClass('active');
      });

    // Add to cart
    $('.product-cart').submit(function(e) {
      e.preventDefault();
  
      var form = $(this);
  
      $.ajax({
        type: form.attr('method'),
        url: form.attr('action'),
        data: form.serialize(),
        success: function(response) {
        productAdded.showModal();
        
        // Haal het aantal items in de winkelwagen op uit de respons
        var itemCount = $(response).find('#cart-count').text();
        
        // Werk de teller bij met het ontvangen aantal items
        $('#cart-count').text(itemCount);
            }
        });
      });
  

    // MOBILE TOGGLE
    // ===============================================

    $('.menu-toggle').on('click', function () {
      if ($('.mobile-menu').hasClass('active')) {
        $('.mobile-menu, .page-overlay, .mobile-menu li.active').removeClass('active');
        $('body').removeClass('overflow-hidden');
      } else {
        $('.mobile-menu, .page-overlay').addClass('active');
        $('body').addClass('overflow-hidden');
      }
    });
    $('.page-overlay').on('click', function () {
      $('.mobile-menu, .page-overlay').removeClass('active');
      $('body').removeClass('overflow-hidden');
    });

    $('.mobile-menu ul li a, .header .logo').on('click', function () {
      $('body').removeClass('overflow-hidden');
    });

    // Close mobile menu on click
    $(document).click(function (event) {
      var $target = $(event.target);
      if (!$target.closest('.mobile-menu').length && !$target.closest('.menu-toggle').length && $('.mobile-menu').hasClass('active')) {
        $('.mobile-menu').removeClass('active');
        $('body').removeClass('overflow-hidden');
      }

    });


    // HEADER SCROLLED
    // ===============================================
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 150) {
        $("header, body").addClass("scrolled");
      }

      if (scroll < 50) {
        $("header, body").removeClass("scrolled");
      }
    });


    AOS.init({
      duration: 400,
      offset: 0, // offset (in px) from the original trigger point
      once: true, // whether animation should happen only once - while scrolling down
      anchorPlacement: 'top-bottom', // define where the AOS animations will be triggered
    });
    window.addEventListener('load', AOS.refresh);

    // SLICK SLIDER
    // ===============================================
    $('.slide').slick('resize');

    $('.homeHeadSlider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: true,
      prevArrow: $('.homePrev'),
      nextArrow: $('.homeNext'),
      dots: false,
      fade: true,
    });

    $('.category-slider').slick({
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: $('.catPrev'),
      nextArrow: $('.catNext'),
      dots: false,
      responsive: [
        {
            breakpoint: 1150,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
            }
        },
        {
          breakpoint: 620,
          settings: {
              slidesToShow: 1,
          }
      }
    ]
    });

    mobileOnlySlider(".intro-cat", false, false, 1279);

    function mobileOnlySlider($slidername, $dots, $arrows, $breakpoint) {
      var slider = $($slidername);
      var settings = {
        mobileFirst: true,
        dots: $dots,
        arrows: $arrows,
        swipeToSlide: true,
        infinite: false,
        slidesToShow: 1.2,
        responsive: [
          {
            breakpoint: $breakpoint,
            settings: "unslick"
          },
          {
            breakpoint: 991,
            settings: {
                slidesToShow: 2.2,
            }
        },
        ]
      };
    
      slider.slick(settings);
    
      $(window).on("resize", function () {
        if ($(window).width() > $breakpoint) {
          return;
        }
        if (!slider.hasClass("slick-initialized")) {
          return slider.slick(settings);
        }
      });
    } // Mobile Only Slider

    mobileOnlySlider2(".brands", false, false, 1279);

    function mobileOnlySlider2($slidername, $dots, $arrows, $breakpoint) {
      var slider = $($slidername);
      var settings = {
        mobileFirst: true,
        dots: $dots,
        arrows: $arrows,
        slidesToShow: 2,
        centerMode: true,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: $breakpoint,
            settings: "unslick"
          },
          {
            breakpoint: 991,
            settings: {
                slidesToShow: 7,
            }
        },
          {
            breakpoint: 767,
            settings: {
                slidesToShow: 6,
            }
        },
        {
          breakpoint: 576,
          settings: {
              slidesToShow: 4,
          }
      }
        ]
      };
    
      slider.slick(settings);
    
      $(window).on("resize", function () {
        if ($(window).width() > $breakpoint) {
          return;
        }
        if (!slider.hasClass("slick-initialized")) {
          return slider.slick(settings);
        }
      });
    } // Mobile Only Slider
    
    $('.popular-slider').slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: $('.popPrev'),
      nextArrow: $('.popNext'),
      dots: false,
      responsive: [
        {
            breakpoint: 1350,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
          breakpoint: 676,
          settings: {
              slidesToShow: 1,
          }
      }
    ]
    });

    $('.featured-slider').slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: $('.fpPrev'),
      nextArrow: $('.fpNext'),
      dots: false,
      responsive: [
        {
            breakpoint: 1350,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
          breakpoint: 676,
          settings: {
              slidesToShow: 1,
          }
      }
    ]
    });

    $('.related-slider').slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: $('.rpPrev'),
      nextArrow: $('.rpNext'),
      dots: false,
      responsive: [
        {
            breakpoint: 1350,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
          breakpoint: 676,
          settings: {
              slidesToShow: 1,
          }
      }
    ]
    });

  });

})(jQuery);